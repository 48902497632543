<template>
  <div class="my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="auth-wrapper">
          <div class="card overflow-hidden">
            <div class="card-title card-header">
              <img src="@/assets/images/logo.svg" width="200px" />
            </div>
            <div class="card-body">
              <b-alert v-model="isAuthError" variant="danger" dismissible>
                {{ authError }}
              </b-alert>

              <b-form @submit.prevent="resetPassword">
                <fieldset :disabled="loading">
                  <b-form-group
                    id="email-input-group"
                    label="E-mail"
                    label-for="email-input"
                  >
                    <b-form-input
                      id="email-input"
                      v-model="email"
                      type="text"
                      placeholder="Digite o e-mail"
                      :class="{ 'is-invalid': submitted && $v.email.$error }"
                    />

                    <div
                      v-if="submitted && $v.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.email.required">
                        {{ $t('login.field_obrigatory') }}
                      </span>
                      <span v-if="!$v.email.email">
                        {{ $t('login.invalid_email') }}
                      </span>
                    </div>
                  </b-form-group>

                  <div class="mt-3 text-center">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="btn-block"
                      v-if="!loading"
                    >
                      Enviar
                    </b-button>

                    <b-spinner
                      variant="primary"
                      label="Carregando"
                      class="mt-1"
                      v-if="loading"
                    />
                  </div>
                  <div class="mt-4 text-center">
                    <router-link to="/login" class="text-muted">
                      <i class="fas fa-sign-in-alt"></i>
                      {{ $t('login.backLogin') }}
                    </router-link>
                  </div>
                </fieldset>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueToast from 'vue-toast-notification';
import Config from '@/config/config';
import Vue from 'vue';

import 'vue-toast-notification/dist/theme-sugar.css';

import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { i18n } from '../../main';

const CONFIG = new Config();
Vue.use(VueToast);

export default {
  data() {
    return {
      email: '',
      submitted: false,
      authError: null,
      loading: false,
      isAuthError: false
    };
  },
  mixins: [validationMixin],
  validations: {
    email: { required, email }
  },
  methods: {
    resetPassword() {
      this.submitted = true;
      this.$v.$touch();

      this.loading = true;

      var axios = require('axios');
      var data = JSON.stringify({
        email: this.email
      });

      var config = {
        method: 'post',
        url: `${CONFIG.API_URL}/usuario/portal/resetPassword`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          Vue.$toast.success(i18n.tc('forgot.successMessage'), {
            position: 'top-right',
            duration: 5000
          });
        })
        .catch((error) => {
          console.log(error);
          Vue.$toast.error(i18n.tc('forgot.errorMessage'), {
            position: 'top-right',
            duration: 5000
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/design/index.scss';

.auth-wrapper {
  width: 320px;
}

.card-header {
  background: $sidebar-dark-bg;
  text-align: center;
  padding: 20px;
}
</style>
